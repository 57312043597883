/*Imagens do carrossel de restaurantes*/
import homenzinhoCorrendo from "../../Images/ImagesCards/homemCorrendo.svg";
import bolinha from "../../Images/ImagesCards/Bolinha.svg";
import dolar from "../../Images/ImagesCards/Dolar.svg";
import grupo from "../../Images/ImagesCards/Grupo.svg";
import tresPontos from "../../Images/ImagesCards/TresPontos.svg";

/*Imagens do carrossel de garçons*/
import malinha from "../../Images/ImagesCards/malinha.svg";
import sorrisinho from "../../Images/ImagesCards/sorrisinho.svg";
import livrinho from "../../Images/ImagesCards/livrinho.svg";
import setas from "../../Images/ImagesCards/duasSetas.svg";
import terninho from "../../Images/ImagesCards/Terninho.svg";




export const restauranteCarrossel =[
    {
        img: homenzinhoCorrendo,
        titulo: "Rápida Resposta às Necessidades de Pessoal:",
        texto: "O aplicativo Gars-On permite que <span>restaurantes encontrem garçons qualificados</span> de forma instantânea, atendendo às <span>flutuações de demanda</span> e garantindo uma resposta rápida às necessidades de pessoal."
    },
    {
        img: grupo,
        titulo: "Flexibilidade na Adaptação da Equipe:",
        texto: "O app oferece a flexibilidade de contratar garçons tanto em <span>regime freelancer</span> quanto <span>CLT</span>, permitindo que os restaurantes <span>ajustem facilmente sua equipe</span> de acordo com as <span>variações sazonais</span> ou <span>eventos especiais.</span>"
    },
    {
        img: dolar,
        titulo: "Otimização de Custos e Recursos:",
        texto: "Ao utilizar o Gars-On, os restaurantes podem otimizar a gestão de pessoal, <span>evitando custos desnecessários</span> e garantindo que a equipe esteja alinhada com a <span>demanda real do estabelecimento.</span>"
    },
    {
        img: bolinha,
        titulo: "Garantia de Qualidade no Atendimento:",
        texto: "Ao <span>conectar-se a garçons qualificados</span> através da plataforma, os restaurantes asseguram um <span>padrão de atendimento elevado</span>. Isso contribui para a satisfação dos clientes e para a construção de uma <span>reputação positiva.</span>"
    },
    {
        img: tresPontos,
        titulo: "Ampliação da Rede de Talentos:",
        texto: "A Gars-On proporciona acesso a uma <span>vasta rede global de garçons talentosos.</span> Isso é especialmente valioso para <span>restaurantes que desejam diversificar sua equipe</span> ou explorar talentos específicos para oferecer <span>experiências gastronômicas únicas.</span>"
    },
];

export const garconCarrossel = [
    {
        img: malinha,
        titulo: "Oportunidades de Trabalho Instantâneas:",
        texto: "O aplicativo Gars-On proporciona aos garçons <span>acesso imediato a   oportunidades de trabalho.</span> Com notificações em tempo real, os profissionais podem <span>encontrar oportunidades de emprego</span> de forma <span>rápida e eficiente.</span>"
    },
    {
        img: setas,
        titulo: "Flexibilidade de Escolha de Modalidade de Trabalho:",
        texto: "O Gars-On oferece a opção de trabalhar tanto como <span>freelancer</span> quanto no regime <span>CLT</span>. Isso proporciona aos garçons uma <span>flexibilidade única</span> para escolher a modalidade que melhor se adapta às suas preferências e circunstâncias."
    },
    {
        img: terninho,
        titulo: "Variedade de Oportunidades e Experiências:",
        texto: "Ao se cadastrar no aplicativo, os garçons têm acesso a uma <span>ampla gama de oportunidades em diferentes estabelecimentos.</span> Isso permite que eles explorem diversas experiências de trabalho, aprimorando suas habilidades e ampliando sua <span>rede profissional.</span>"
    },
    {
        img: livrinho,
        titulo: "Acesso Facilitado a Cursos Especializados:",
        texto: "O aplicativo <span>Gars-On</span> pode <span>oferecer parcerias com instituições e empresas</span> que oferecem <span>cursos especializados para garçons.</span> Isso permite que os profissionais tenham acesso fácil a programas de treinamento que aprimoram suas <span>habilidades e conhecimentos.</span>"
    },
    {
        img: sorrisinho,
        titulo: "Benefícios Exclusivos para Usuários do App:",
        texto: "<span>Parcerias estratégicas</span> podem resultar em <span>benefícios exclusivos</span> para usuários do aplicativo, como descontos em cursos ou acesso privilegiado a eventos de Gars-On."
    },
];
