import React from "react";
import './Redirecionamento.css';
import fundo from "../../Images/fundo-redirex.svg";
import { NavLink } from "react-router-dom";

const Redirecionamento = () => {
    return(
        <section className="redirex-container">
            <p className="redirex-item-titulo">OOPS! PÁGINA NÃO ENCONTRADA.</p>
            <p className="redirex-item-conteudo">Parece que não encontramos o que você está tentando acessar.</p>
            <button className="button-redirex-home"><NavLink to="/">VOLTAR À PAGINA INICIAL</NavLink></button>

            <img src={fundo} className="fundo-redirex" alt="fundo da imagem"></img>
            
        </section>
    );
};

export default Redirecionamento;