import React from "react";
import {NavLink} from 'react-router-dom';
import './Footer.css';
import Logo from '../../Images/LOGO_VETORIZADA.svg';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};


const Footer = () => {
    return(
        <footer>

            <div className="footer-container">

                <div className="footer-item">
                    <NavLink to="/" onClick={scrollToTop} ><img className="logo-footer" src={Logo} alt="gars-on"/></NavLink>
                    <p>No Gars-On conectamos de maneira inteligente donos de restaurantes a garçons qualificados</p>
                </div>

                <div className="footer-item information">
                    <h3>Mais Informações</h3>
                    <ul>
                        <li><NavLink className="foot-item" onClick={scrollToTop} to='/dicas'>Dicas</NavLink></li>
                        <li><NavLink className="foot-item" onClick={scrollToTop} to='/noticias'>Notícias</NavLink></li>
                        <li><NavLink className="foot-item" onClick={scrollToTop} to='/campanhas'>Campanhas</NavLink></li>
                    </ul>
                </div>

                <div className="footer-item contacts">
                    <h3>Contate-nos</h3>

                    <div className="icons">
                    <a href="https://www.instagram.com/gars.online?igsh=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr" target="_blank" rel="noreferrer"><svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.05 0H31.95C39.15 0 45 5.85 45 13.05V31.95C45 35.4111 43.6251 38.7304 41.1777 41.1777C38.7304 43.6251 35.4111 45 31.95 45H13.05C5.85 45 0 39.15 0 31.95V13.05C0 9.58892 1.37491 6.26961 3.82226 3.82226C6.26961 1.37491 9.58892 0 13.05 0ZM12.6 4.5C10.4517 4.5 8.39148 5.35339 6.87244 6.87244C5.35339 8.39148 4.5 10.4517 4.5 12.6V32.4C4.5 36.8775 8.1225 40.5 12.6 40.5H32.4C34.5483 40.5 36.6085 39.6466 38.1276 38.1276C39.6466 36.6085 40.5 34.5483 40.5 32.4V12.6C40.5 8.1225 36.8775 4.5 32.4 4.5H12.6ZM34.3125 7.875C35.0584 7.875 35.7738 8.17132 36.3012 8.69876C36.8287 9.22621 37.125 9.94158 37.125 10.6875C37.125 11.4334 36.8287 12.1488 36.3012 12.6762C35.7738 13.2037 35.0584 13.5 34.3125 13.5C33.5666 13.5 32.8512 13.2037 32.3238 12.6762C31.7963 12.1488 31.5 11.4334 31.5 10.6875C31.5 9.94158 31.7963 9.22621 32.3238 8.69876C32.8512 8.17132 33.5666 7.875 34.3125 7.875ZM22.5 11.25C25.4837 11.25 28.3452 12.4353 30.455 14.545C32.5647 16.6548 33.75 19.5163 33.75 22.5C33.75 25.4837 32.5647 28.3452 30.455 30.455C28.3452 32.5647 25.4837 33.75 22.5 33.75C19.5163 33.75 16.6548 32.5647 14.545 30.455C12.4353 28.3452 11.25 25.4837 11.25 22.5C11.25 19.5163 12.4353 16.6548 14.545 14.545C16.6548 12.4353 19.5163 11.25 22.5 11.25ZM22.5 15.75C20.7098 15.75 18.9929 16.4612 17.727 17.727C16.4612 18.9929 15.75 20.7098 15.75 22.5C15.75 24.2902 16.4612 26.0071 17.727 27.273C18.9929 28.5388 20.7098 29.25 22.5 29.25C24.2902 29.25 26.0071 28.5388 27.273 27.273C28.5388 26.0071 29.25 24.2902 29.25 22.5C29.25 20.7098 28.5388 18.9929 27.273 17.727C26.0071 16.4612 24.2902 15.75 22.5 15.75Z" fill="#A3A3A3"/>
                    </svg>
                    </a>           
                    
                    <a href="http://linkedin.com/in/gars-on-b72379307" target="_blank" rel="noreferrer">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.1111 0C40.4077 0 41.6512 0.515078 42.5681 1.43192C43.4849 2.34877 44 3.59227 44 4.88889V39.1111C44 40.4077 43.4849 41.6512 42.5681 42.5681C41.6512 43.4849 40.4077 44 39.1111 44H4.88889C3.59227 44 2.34877 43.4849 1.43192 42.5681C0.515078 41.6512 0 40.4077 0 39.1111V4.88889C0 3.59227 0.515078 2.34877 1.43192 1.43192C2.34877 0.515078 3.59227 0 4.88889 0H39.1111ZM37.8889 37.8889V24.9333C37.8889 22.8199 37.0493 20.7929 35.5549 19.2985C34.0604 17.804 32.0335 16.9644 29.92 16.9644C27.8422 16.9644 25.4222 18.2356 24.2489 20.1422V17.4289H17.4289V37.8889H24.2489V25.8378C24.2489 23.9556 25.7644 22.4156 27.6467 22.4156C28.5543 22.4156 29.4248 22.7761 30.0665 23.4179C30.7083 24.0597 31.0689 24.9301 31.0689 25.8378V37.8889H37.8889ZM9.48444 13.5911C10.5736 13.5911 11.6181 13.1584 12.3883 12.3883C13.1584 11.6181 13.5911 10.5736 13.5911 9.48444C13.5911 7.21111 11.7578 5.35333 9.48444 5.35333C8.38881 5.35333 7.33804 5.78857 6.56331 6.56331C5.78857 7.33804 5.35333 8.38881 5.35333 9.48444C5.35333 11.7578 7.21111 13.5911 9.48444 13.5911ZM12.8822 37.8889V17.4289H6.11111V37.8889H12.8822Z" fill="#777777"/>
                        </svg>
                    </a>
                    </div>
                    
                </div>
            </div>
            <p className="copyright" >Gars-on © 2024 Todos os direitos reservados.</p>
        </footer>
    );
};

export default Footer;