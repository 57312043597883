import React, { useState } from 'react';
import { axiosInstancePrivate } from '../../api/axiosInstances';
import './Home.css';
import './SectionMotivos.css';
import Logo from '../../Images/LOGO_VETORIZADA.svg';
import Iphone from '../../Images/iPhone-15-Pro-home.svg';
import RetanguloEsquerdo from '../../Images/Retangulo-direito-home-parceiros.svg';
import RetanguloDireito from '../../Images/Retangulo-esquerdo-home-parceiros.svg';
import RetanguloCantoInferiorEsquerdo from '../../Images/Retangulo-Canto-Inferior-Esquerdo.svg';
import TrianguloInternoAoRetangulo from '../../Images/Triangulo-Interno-Ao-Retangulo.svg';
import ElipseInternaAoRetangulo from '../../Images/Elipse-Interna-Ao-Retangulo.svg';
import Carrossel from '../../components/Carrossel/Carrossel';
import logoSVG from '../../Images/logoSVG.svg'
import LuzDireita from '../../Images/luz-lateral-direita-home.svg';
import LuzEsquerda from '../../Images/luz-lateral-esquerda-home.svg';

import { restauranteCarrossel } from './CardsContent';
import { garconCarrossel } from './CardsContent';
import CarrosselParceiros from '../../components/Carrossel/CarrosselParceiros';

import './SectionParceiros.css';
import './SectionContato.css';

const enviaJson = async (userType, email, subject, content) => {
  const json = JSON.stringify({
    userType: userType,
    email: email,
    subject: subject,
    content: content,
  });
  try {
    const response = await axiosInstancePrivate.post(
      '/api/contacts/send',
      json
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const Home = ({ onButtonClick }) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [subjectError, setSubjectError] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const pegaDados = async (event) => {
    event.preventDefault();
    let input0 = document.querySelector('#email');
    let input1 = document.querySelector('#assunto');
    let input3 = document.querySelector('#mensagem');
    let input4 = document.querySelector('#seletor');
    let email = input0.value;
    let subject = input1.value;
    let content = input3.value;
    let userType = input4.options[input4.selectedIndex].value;

    setIsLoading(true);

    try {
      const responseData = await enviaJson(userType, email, subject, content);
      setMessage(responseData.message);
      setMessageType('success');
      setEmailError(null);
      setSubjectError(null);
      setContentError(null);
    } catch (error) {
      const errorData = JSON.parse(error.response.data.error.message);

      if (errorData.email || errorData.subject || errorData.content) {
        setEmailError(errorData.email);
        setSubjectError(errorData.subject);
        setContentError(errorData.content);
        setMessage(null);
        setMessageType(null);
      } else {
        setMessage(errorData);
        setMessageType('error');
        setEmailError(null);
        setSubjectError(null);
        setContentError(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="home-container">
      <section className="apresentacao-item">
        <div className="logo-item">
          <img className="logo-home" src={Logo} alt="gars-on"></img>
        </div>

        <div className="conteudo-item">
          <div className="texto-item">
            <h1>Encontre os melhores garçons para seu restaurante agora!</h1>
            <p>
              A Gars-On é mais do que uma plataforma de recrutamento. Somos{' '}
              <span>catalisadores de oportunidades</span>, conectando{' '}
              <span>donos de restaurantes a garçons qualificados</span> em tempo
              real. Nossa abordagem centrada na <span>tecnologia</span> e focada
              na <span>eficiência</span>, tem como objetivo transformar a
              maneira como a indústria no universo da hospitalidade lida com o
              recrutamento e gestão de pessoa.
            </p>
          </div>

          <figure className="imagens-item">
            <img className="iphone" src={Iphone} alt="Iphone-15"></img>
            <div className="fundo"></div>
          </figure>
        </div>

        <div className="botoes-item">
          <button onClick={onButtonClick}>
            <a className="conteudo-botao">Baixe o App</a>
          </button>
          <button>
            <a
              className="conteudo-botao"
              href="#forms"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Entre em contato conosco
            </a>
          </button>
        </div>
      </section>

      <section className="motivos-container">
        <p className="porque-item">
          <span>Porque usar o </span>
        <p className="logo-porque"> GARS-ON?</p>
        </p>

        {/*<div className="luz-lateral-direita"  ></div>*/}
        <p className="restaurante-garcons-item">Para restaurantes:</p>

        <div className="restaurante-carrossel">
          <Carrossel carrossel={restauranteCarrossel} />
        </div>

        <div className="luz-lateral-esquerda" ></div>

        <p className="restaurante-garcons-item">Para garçons:</p>
        <div className="garcon-carrossel">
          <Carrossel carrossel={garconCarrossel} />
        </div>
      </section>

      <section className="parceiros-container">
        <img
          className="retangulo-esquerdo"
          src={RetanguloEsquerdo}
          alt="retangulo-esquerdo"
        ></img>

        <div className="parceiros-texto-item">
          <h1>Nossos Parceiros</h1>
          <p>
            Cada restaurante que se junta a nós traz consigo uma riqueza de
            experiência culinária e um compromisso inabalável com a excelência
            no serviço. Estamos constantemente em busca por novas parcerias que
            estejam dispostas a crescer e revolucionar os seus modelos de
            negócios juntamente com nossa soluções inovadoras.{' '}
          </p>
          {/*<CarrosselParceiros />*/}
        </div>

        <img
          className="retangulo-direito-parceiros"
          src={RetanguloDireito}
          alt="retangulo-direito"
        ></img>
      </section>

      <section className="contact-container">
        <div className="contact-item">
          <p className="texto-duvida">ALGUMA DÚVIDA?</p>
          <figure className="imagem-fundo">
            <img src={RetanguloCantoInferiorEsquerdo} alt="imagem"></img>
            <img src={TrianguloInternoAoRetangulo} alt="imagem"></img>
            <img src={ElipseInternaAoRetangulo} alt="imagem"></img>
            <p>
              <span>ENTRE EM</span>
              <br></br>CONTATO.
            </p>
          </figure>
        </div>

        <div className="enquadro">
          <form className="forms-container" id="forms">
            <div className="forms-item">
              <select id="seletor">
                <option value="Restaurante">Restaurante</option>
                <option value="Garçom">Garçom</option>
              </select>
            </div>
            <div className="forms-item">
              <input type="email" placeholder="Email" id="email"></input>
              {emailError && <p className="error-message">{emailError}</p>}
            </div>
            <div className="forms-item">
              <textarea
                type="text"
                placeholder="Assunto"
                id="assunto"
              ></textarea>
              {subjectError && <p className="error-message">{subjectError}</p>}
            </div>
            <div className="forms-item">
              <textarea
                type="text"
                placeholder="Mensagem"
                id="mensagem"
              ></textarea>
              {contentError && <p className="error-message">{contentError}</p>}
              {message && <p className={`${messageType}-message`}>{message}</p>}
            </div>

            <button
              className="button-forms"
              onClick={pegaDados}
              disabled={isLoading}
            >
              {isLoading ? 'Enviando...' : 'Enviar'}
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Home;
