import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from './components/Footer/Footer'
import Dicas from './Pages/Dicas/Dicas';
import Campanhas from './Pages/Campanhas/Campanhas'
import Noticias from './Pages/Noticias/Noticias';
import Home from './Pages/Home/Home'
import Popup from './components/Popup/Popup'
import { useState } from "react";
import Redirecionamento from "./Pages/Redirecionamento/Redirecionamento";


function App(){

  const [buttonPopup, setButtonPopup] = useState(false);

  const handleButtonClick = () => {
    setButtonPopup(true);
  };

  return (
    <BrowserRouter>
    <Navbar onButtonClick={handleButtonClick}/>
    <Popup trigger={buttonPopup} setTrigger={setButtonPopup}/>
      <Routes>
        <Route path='/' element={<Home onButtonClick={handleButtonClick}/>}/>
        <Route path='/dicas' element={<Dicas/>}/>
        <Route path='/noticias' element={<Noticias/>}/>
        <Route path='/campanhas' element={<Campanhas/>}/>
        <Route path='/*' element={<Redirecionamento/>}/>
      </Routes>
    <Footer/>
    </BrowserRouter>
  );
};

export default App;