import React from "react";
import dedinho from '../../Images/Dedinho-dicas.svg';
import dedinhoMobile from '../../Images/dedinho-mobile.svg';
import "./Dicas.css";


const cards = [
    {
        titulo:"Treinamento insuficiente:",
        corTitulo: "#FFFFFF",
        corTexto: "#FFFFFF",
        conteudo: "Existem várias empresas nacionais que oferecem serviços de Treinamento e Desenvolvimento de Equipe, com foco específico no setor de restaurantes no Brasil. Aqui estão algumas opções que você pode considerar: Sebrae, Senac, Abrasel, iFood Empresas e Instituto da Hospitalidade.",
        background: "linear-gradient(180deg, rgba(49, 48, 77, 1), rgba(49, 48, 77, 0) 105%)"
    },
    {
        titulo:"Atendimento Personalizado:",
        corTitulo: "#171E3F",
        corTexto: "#31304D",
        conteudo: "Ofereça um atendimento personalizado, tratando cada cliente de forma única e especial. Conheça os clientes pelo nome, se possível, e esteja atento às suas preferências. Um serviço personalizado cria uma conexão emocional com os clientes e os faz sentir valorizados.",
        background: "linear-gradient(180deg, rgba(240, 236, 229, 1) 45%, rgba(240, 236, 229, 0) 140%)"
    },
    {
        titulo:"Solicite Feedback e Esteja Aberto a Críticas:",
        corTitulo: "#FFFFFF",
        corTexto: "#F0ECE5",
        conteudo: "Encoraje os clientes a fornecerem feedback sobre sua experiência no restaurante e demonstre que você valoriza suas opiniões. Esteja aberto a críticas construtivas e use o feedback recebido para fazer melhorias contínuas no serviço, cardápio e experiência geral do cliente.",
        background: "linear-gradient(180deg, rgba(23, 30, 63, 1), rgba(49, 48, 77, 1) 100%)"
    },
    {
        titulo:"Resolva Problemas de Forma Proativa:",
        corTitulo: "#FFFFFF",
        corTexto: "#F0ECE5",
        conteudo: "Se surgirem problemas ou insatisfações por parte dos clientes, resolva-os de forma proativa e rápida. Assuma a responsabilidade pelos erros e faça o possível para corrigi-los, oferecendo soluções satisfatórias e compensações quando necessário.",
        background: "linear-gradient(180deg, rgba(23, 30, 63, 1), rgba(49, 48, 77, 1) 100%)"
    },
    {
        titulo:"Ofereça Programas de Fidelidade e Recompensas:",
        corTitulo: "#FFFFFF",
        corTexto: "#F0ECE5",
        conteudo: "Implemente programas de fidelidade e recompensas para incentivar a repetição de negócios e reconhecer a lealdade dos clientes. Isso pode incluir descontos exclusivos, ofertas especiais para clientes frequentes e programas de pontos que podem ser trocados por prêmios ou benefícios.",
        background: "linear-gradient(180deg, rgba(49, 48, 77, 1), rgba(49, 48, 77, 0) 105%)"
    },
    {
        titulo:"Mostre Gratidão e Reconhecimento:",
        corTitulo: "#31304D",
        corTexto: "#31304D",
        conteudo: "Demonstre gratidão e reconhecimento aos clientes por escolherem seu restaurante. Isso pode ser feito por meio de gestos simples, como um sorriso sincero, um agradecimento verbal, ou até mesmo um cartão de agradecimento enviado após a visita ao restaurante. Mostrar apreço genuíno ajuda a fortalecer os laços com os clientes e a construir relacionamentos duradouros.",
        background: "linear-gradient(180deg, rgba(240, 236, 229, 1) 45%, rgba(240, 236, 229, 0) 140%)"
    }
];

const Dicas = () => {
    return(
        <div className="dicas-container">

            <h1 className="titulo-dicas">Dicas</h1>
        {/* para que o dedo seja mostrado é só descomentar
            <img className="dedinho" src={dedinho} alt="dedinho"></img>
            <img className="dedinho-mobile" src={dedinhoMobile} alt="dedinho"></img>
        */}
            <div className="cards-container">
                {cards.map((content, key)=>
                 <div className="cards-item" style={{ backgroundImage: content.background }} key={key}>
                    <h3 style={{color: content.corTitulo}}>{content.titulo}</h3>
                    <p style={{color: content.corTexto}}>{content.conteudo}</p>
                </div>
                )}
            </div>


        </div>
    );
};


export default Dicas;