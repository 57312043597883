import React, { useEffect, useState } from 'react';
import { axiosInstancePublic } from '../../api/axiosInstances';
import copinho from '../../Images/copinho-noticias.svg';
import luzEsquerda from '../../Images/luz-esquerda-noticias.svg';
import luzDireita from '../../Images/luz-direita-noticias.svg';
import logoErro from "../../Images/Logo-erro-campanhas.jpg"
import './Noticias.css';
import botaoExpandir from '../../Images/arrow-texto-noticias.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Noticias = () => {
  const [noticia, setNoticia] = useState({
    coverURL: '',
    altText: '',
    text: 'Carregando...',
    isExpanded: false,
  });

  useEffect(() => {
    axiosInstancePublic
      .get('/api/update?populate=*')
      .then((response) => {
        setNoticia({
          coverURL:
            BASE_URL + response.data.data.attributes.cover.data.attributes.url,
          altText:
            response.data.data.attributes.cover.data.attributes.alternativeText,
          text: response.data.data.attributes.text,
          isExpanded: false,
        });
      })
      .catch((error) => {
        setNoticia({
          coverURL: logoErro,
          altText: '',
          text: 'Erro ao carregar a notícia, tente novamente mais tarde!',
          isExpanded: false,
        });
      });
  }, []);

  const toggleExpandido = () => {
    setNoticia((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  return (
    <div className="noticias-container">
      <div className="titulo-noticias">
        <h1>Noticias</h1>
      </div>

      <div className="conteudo-noticias-container">
        <figure className="figura-noticias-container">
          <div className="figura-noticias"></div>
          <div className="enquadro-imagem-noticias">
            <img
              className="imagem-noticia"
              src={noticia.coverURL}
              alt={noticia.altText}
            ></img>
          </div>
          <img className="copinho" src={copinho} alt="copinho"></img>
        </figure>

        <div className="texto-container-noticias">
          <p
            className={`texto-imagem-noticias ${
              noticia.isExpanded ? 'expandido' : ''
            }`}
          >
            {noticia.text}
          </p>
          <img
            src={botaoExpandir}
            className={`botao-expandir ${
              noticia.isExpanded ? 'virado-baixo' : ''
            }`}
            onClick={toggleExpandido}
            alt="botao"
          ></img>
        </div>
      </div>

      <img src={luzEsquerda} className="luz-esquerda-noticias" alt="luz"></img>
      <img src={luzDireita} className="luz-direita-noticias" alt="luz"></img>
    </div>
  );
};

export default Noticias;
