import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useEffect, useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Carrossel.css';


const Carrossel = ({carrossel}) =>{

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [spaceBetween, setSpaceBetween] = useState(-50);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < 400){
                setSlidesPerView(1);
                setSpaceBetween(30);
            }else if (window.innerWidth < 768) {
                setSlidesPerView(1);
                setSpaceBetween(0);
            }else if(window.innerWidth < 1200) {
                setSlidesPerView(2);
                setSpaceBetween(40);
            }else {
                setSlidesPerView(3);
                setSpaceBetween(-50);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            pagination={{ clickable: true }}
            navigation= {true}
            loop={true}
        >

            {carrossel.map((content, key) => (
                <SwiperSlide key={key}>
                    <div className="card-item" >
                        <div className="card-header">
                            <img src={content.img} alt="icon"></img>
                            <h3>{content.titulo}</h3>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: content.texto }}></p>
                    </div>
                </SwiperSlide>
            ))}
        
        </Swiper>

       
        
    );
};

export default Carrossel;