import React, { useEffect, useState } from 'react';
import { axiosInstancePublic } from '../../api/axiosInstances';
import './Campanhas.css';
import botaoExpandir from '../../Images/arrow-texto-noticias.svg';
import logoErro from "../../Images/Logo-erro-campanhas.jpg"

import luz from '../../Images/luz-campanhas.svg';
import microfone from '../../Images/microfone-campanhas.svg';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Campanhas = () => {
  const [campanha, setCampanha] = useState({
    coverURL: '',
    altText: '',
    text: 'Carregando...',
    isExpanded: false,
  });

  useEffect(() => {
    axiosInstancePublic
      .get('/api/campaign?populate=*')
      .then((response) => {
        console.log(
          response.data.data.attributes.cover.data.attributes.alternativeText
        );
        setCampanha({
          coverURL:
            BASE_URL + response.data.data.attributes.cover.data.attributes.url,
          altText:
            response.data.data.attributes.cover.data.attributes.alternativeText,
          text: response.data.data.attributes.text,
          isExpanded: false,
        });
      })
      .catch((error) => {
        setCampanha({
          coverURL: logoErro,
          altText: '',
          text: 'Erro ao carregar a campanha, tente novamente mais tarde!',
          isExpanded: false,
        });
      });
  }, []);

  const toggleExpandido = () => {
    setCampanha((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  return (
    <div className="campanhas-container">
      <div className="titulo-campanhas">
        <h1>Campanhas</h1>
      </div>

      <div className="conteudo-campanhas-container">
        <div className="texto-container-campanhas">
          <p
            className={`texto-imagem-campanhas ${
              campanha.isExpanded ? 'expandido' : ''
            }`}
          >
            {campanha.text}
          </p>
          <img
            src={botaoExpandir}
            className={`botao-expandir ${
              campanha.isExpanded ? 'virado-baixo' : ''
            }`}
            onClick={toggleExpandido}
            alt="botao"
          ></img>
        </div>

        <img className="luz-campanhas" src={luz} alt="luz"></img>

        <figure className="figura-campanhas-container">
          <div className="enquadro-imagem-campanhas">
            <img
              className="imagem-campanhas"
              src={campanha.coverURL}
              alt={campanha.altText}
            ></img>
          </div>
          <img className="microfone" src={microfone} alt="microfone"></img>
        </figure>
      </div>
    </div>
  );
};

export default Campanhas;
